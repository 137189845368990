import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Loader from '../../Loader';
import './styles.scss';
import BadgeImage from './images/badge.png';
import TEAM_ITEMS from '../../../json/team.json';
import CONTACTS from '../../../json/contacts.json';

import OrderBtn from '../../OrderBtn';

export const SECTION_MENU_TITLE = 'Команда';
export const SECTION_NAME = 'Наши мастера';
export const SECTION_LINK = '#team';

const Team = () => {
  const [team, setTeam] = useState(TEAM_ITEMS);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get('/json/team.json')
      .then(({ data }) => {
        setTeam(data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
        if (window.lightbox) {
          window.lightbox.option({
            wrapAround: true,
            alwaysShowNavOnTouchDevices: true,
            albumLabel: 'Фото %1 из %2',
          });
        }
      });
  }, []);

  return (
    <div className="section gray" id="team">
      <div className="container">
        {loading && <Loader />}
        <h3 className="section-title">{SECTION_NAME}</h3>
        <h3 className="section-subtitle">Команда {CONTACTS.titleEn}</h3>
        <div className="team-items">
          {team.map(item => {
            const alt = item.alt ? item.alt : `Мастер ${item.name}`;
            const title = item.title ? item.title : `Мастер ${item.name}`;
            return (
              <div className="member-block" key={item.image}>
                <a
                  href={process.env.PUBLIC_URL + item.image}
                  data-lightbox={SECTION_NAME}
                  data-title={title}
                >
                  <img
                    src={process.env.PUBLIC_URL + item.thumbnail}
                    className="img-fluid"
                    alt={alt}
                  />
                  <span className="member-name">{item.name}</span>
                  <span className={`member-position${item.badge ? ' with-badge' : ''}`}>
                    {item.badge && <img src={BadgeImage} alt="Badge" />}
                    <span className="position-name">{item.position}</span>
                  </span>
                </a>
              </div>
            );
          })}
        </div>
        <OrderBtn centered />
      </div>
    </div>
  );
};

export default Team;
